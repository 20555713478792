<template>
  <v-col>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <div class="d-flex align-center justify-space-between">
      <v-btn
        class="mx-1 white--text"
        color="color_green"
        @click="openAddItem('document')"
      >
        <v-icon class="pr-2">
          mdi-plus
        </v-icon>
        Add document type
      </v-btn>

      <!-- Date picker -->
      <div>
        <v-menu
          v-model="showDatePicker"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="dateSelectedText"
              :style="{ width: '290px' }"
              label="Date"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="dateSelected"
            no-title
            scrollable
            :max="moment().format('YYYY-MM-DD')"
          >
            <v-btn
              color="primary"
              :disabled="!dateSelected"
              @click="closeDatePicker()"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </div>
    </div>

    <br>
    <!-- Expansion Panel -->
    <v-expansion-panels multiple>
      <v-expansion-panel
        v-for="(element, index) in ticketingConfigurationCopy.config"
        :key="index"
      >
        <v-expansion-panel-header>
          <div class="d-flex align-center justify-space-between">
            <span class="title">
              {{ element.document_type }}
            </span>
            <div class="mx-2">
              <v-btn
                icon
                @click.stop="openEditItem('document', element)"
              >
                <v-icon>
                  mdi-pencil
                </v-icon>
              </v-btn>
              <v-btn
                icon
                @click.stop="openTranslationModal('document', element)"
              >
                <v-icon>
                  mdi-google-translate
                </v-icon>
              </v-btn>
              <v-btn icon>
                <delete
                  :size="24"
                  @deleted="deleteItem('document', element)"
                />
              </v-btn>
            </div>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="d-flex align-center mb-4">
            <v-btn
              class="mx-2"
              fab
              dark
              small
              color="color_green"
              @click="openAddItem('meteo', element.document_type)"
            >
              <v-icon>
                mdi-plus
              </v-icon>
            </v-btn>
            <div>
              Add Meteo Type
            </div>
          </div>
          <v-data-table
            :headers="headers"
            :items="element.meteo_types"
            class="custom-data-table"
            hide-default-footer
            disable-pagination
          >
            <template v-slot:[`item.is_auto`]="{ item }">
              <v-icon :color="item.is_auto ? 'green' : 'red'">
                {{ item.is_auto ? 'mdi-check-circle' : 'mdi-close-circle' }}
              </v-icon>
            </template>
            <template v-slot:[`item.is_manual`]="{ item }">
              <v-icon :color="item.is_manual ? 'green' : 'red'">
                {{ item.is_manual ? 'mdi-check-circle' : 'mdi-close-circle' }}
              </v-icon>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                icon
                @click="openEditItem('meteo', element, item)"
              >
                <v-icon size="20">
                  mdi-pencil
                </v-icon>
              </v-btn>
              <v-btn
                icon
                @click.stop="openTranslationModal('meteo', item)"
              >
                <v-icon size="20">
                  mdi-google-translate
                </v-icon>
              </v-btn>
              <v-btn icon>
                <delete
                  :size="20"
                  @deleted="deleteItem('meteo', element, item)"
                />
              </v-btn>
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <!-- Modal -->
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title v-if="!isEditing">
          Add {{ dialogTitle }}
        </v-card-title>
        <v-card-title v-else>
          Edit {{ dialogTitle }}
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-if="dialogTitle == 'Document type'"
            ref="documentField"
            v-model="formModel.config.document_type"
            :label="dialogTitle"
            :counter="255"
            :rules="rules"
          />
          <div v-else-if="dialogTitle == 'Meteo type'">
            <v-text-field
              v-model="meteoTypeName"
              :label="dialogTitle"
              :counter="255"
              :rules="rules"
            />

            <v-checkbox
              v-model="is_auto_checkbox"
              label="Auto"
            />
            <v-checkbox
              v-model="is_manual_checkbox"
              label="Manual"
            />
            <v-alert
              v-model="showErrorMessage"
              type="error"
              dismissible
            >
              {{ errorMessage }}
            </v-alert>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-alert
            :value="alert"
            color="red accent-4"
            dark
            transition="scale-transition"
          >
            item already exist
          </v-alert>
          <v-spacer />
          <v-btn
            v-if="!isEditing"
            light
            color="color_green"
            style="color:white"
            class="ml-4"
            small
            @click="addItem(dialogTitle, formModel)"
          >
            Create {{ dialogTitle }}
          </v-btn>
          <v-btn
            v-else
            light
            color="color_green"
            style="color:white"
            class="ml-4"
            rounded
            small
            @click="editItem(dialogTitle, formModel)"
          >
            Edit {{ dialogTitle }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <div class="my-5 mx-1">
      <br>
      <h3 class="my-5">
        New translations
      </h3>
      <v-data-table
        :headers="translationHeaders"
        :items="TicketingServiceTranslationInput.translations"
        hide-default-footer
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.id }}</td>
            <td>{{ item.type }}</td>
            <td>{{ item.lang }}</td>
            <td>{{ item.value }}</td>
          </tr>
        </template>
      </v-data-table>
    </div> -->

    <!-- Dialog Traduzione -->
    <v-dialog
      v-model="showTranslationModal"
      width="500"
    >
      <v-card>
        <v-card-title>
          {{ translationDialogTitle }}
        </v-card-title>
        <v-card-text>
          <v-select
            v-model="selectedLanguage"
            :items="setLanguages"
            :value="setLanguages.text"
            label="Select Language"
            @change="changeSelectedLanguage(selectedLanguage)"
          />
          <v-text-field
            v-model="itemName"
            disabled
          />
          <v-text-field
            v-model="translationText"
            label="Translation"
            multi-line
            rows="3"
          />
        </v-card-text>
        <v-card-actions class="d-flex justify-space-between">
          <v-btn
            text
            @click="showTranslationModal = false"
          >
            Cancel
          </v-btn>
          <v-btn
            class="white--text"
            color="color_green"
            :disabled="!isTranslationValid"
            @click="addTranslation"
          >
            Add translation
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Pulsanti  -->
    <v-row class="mt-10 justify-space-between mx-1">
      <v-btn
        dark
        color="warning"
        @click="$router.go(-1)"
      >
        Close
      </v-btn>
      <v-btn
        dark
        color="color_green"
        @click="confirmConfiguration()"
      >
        Confirm
      </v-btn>
    </v-row>
  </v-col>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import { isEqual } from 'lodash';
import moment from 'moment-business-days';
import { LANGUAGES, DEFAULT_METEO_TYPES, PUNTUALE_METEO_TYPES } from '@/constants.js';
// import Vue from 'vue';

export default {
  components: {
    delete: () => import('@/components/utils/Delete')
  },
  data() {
    return {
      ticketingConfigurationCopy: [],
      languages: [],
      overlay: false,
      showErrorMessage: false,
      errorMessage: '',
      headers: [
        { text: 'METEO TYPE', value: 'name', width: '40%' },
        { text: 'AUTO', value: 'is_auto', sortable: false, align: 'center', width: '20%' },
        { text: 'MANUAL', value: 'is_manual', sortable: false, align: 'center', width: '20%' },
        { text: 'ACTIONS', value: 'actions', sortable: false, align: 'center', width: '20%' }
      ],
      translationHeaders: [
        { text: 'ID', value: 'id' },
        { text: 'Type', value: 'type' },
        { text: 'Lang', value: 'lang' },
        { text: 'Value', value: 'value' }
      ],
      alert: false,
      dialog: false,
      isEditing: false,
      ticket_id: '',
      formModel: {
        service_id: '',
        ticket_id: '',
        config: {
          document_type: '',
          meteo_types: []
        }
      },
      documentTypeSelected: '',
      meteoTypeName: '',
      is_auto_checkbox: false,
      is_manual_checkbox: false,
      defaultMeteoTypes: [],
      dialogTitle: '',
      dialogValue: '',
      oldItem: '',
      dateSelected: '',
      dateSelectedText: '',
      showDatePicker: false,
      ticketingItemsDeleted: {
        ticket_id: '',
        document_id: [],
        meteo_id: []
      },
      showTranslationModal: false,
      translationDialogTitle: '',
      selectedLanguage: '',
      translationText: '',
      itemName: '',
      TicketingServiceTranslationInput: {
        service_id: '',
        ticket_id: '',
        translations: []
      },
      ticketingTranslationConfig: [],
      // Nuove variabili traduzioni
      isLanguageSelected: false,
      selectedLanguageText: '',
      selectedLanguageValue: '',
      rules: [value => !!value || 'Required.']
    };
  },
  computed: {
    ...mapState(['services', 'ticketing']),

    setLanguages() {
      return LANGUAGES;
    },
    DEFAULT_METEO_TYPES() {
      return DEFAULT_METEO_TYPES;
    },
    PUNTUALE_METEO_TYPES() {
      return PUNTUALE_METEO_TYPES;
    },
    isTranslationValid() {
      return this.selectedLanguage !== '' && this.translationText !== '';
    }
  },
  async mounted() {
    if (this.services.service_id == '') {
      this.$router.go(-1);
    } else {
      await this.getTicketingConfiguration();
      await this.getTicketingTranslation();
    }

    this.setDefaultDate();
  },
  methods: {
    ...mapActions([
      'storeGetTicketingServiceConfiguration',
      'createTicketingServiceAction',
      'editTicketingServiceAction',
      'deleteTicketingServiceAction',
      'storeTranslationServices',
      'storeGetTranslationNodes',
      'storeGetTicketingServiceTranslation',
      'createEditTicketingServiceTranslationAction'
    ]),
    async getTicketingConfiguration() {
      this.overlay = true;
      await this.storeGetTicketingServiceConfiguration(this.services.service_id);
      this.overlay = false;

      if (this.ticketing.ticketingServiceConfiguration) {
        this.ticketingConfigurationCopy = JSON.parse(JSON.stringify(this.ticketing.ticketingServiceConfiguration));
      }

      if (this.ticketing.ticketingServiceConfiguration.date_limit) {
        this.dateSelected = this.ticketing.ticketingServiceConfiguration.date_limit;
        this.dateSelectedText = this.ticketing.ticketingServiceConfiguration.date_limit;
      } else {
        this.setDefaultDate();
      }

      // Set TicketingServiceTranslationInput
      this.TicketingServiceTranslationInput.service_id = this.ticketing.ticketingServiceConfiguration.service_id;
      this.TicketingServiceTranslationInput.ticket_id = this.ticketing.ticketingServiceConfiguration.ticket_id;
    },
    async getTicketingTranslation() {
      if (this.ticketingConfigurationCopy.ticket_id) {
        this.setLanguages.forEach(element => {
          const langArray = element.value.split('-');
          this.languages.push(langArray[0]);
        });

        const data = {
          ticket_id: this.ticketingConfigurationCopy.ticket_id,
          lang: this.languages
        };

        this.overlay = true;
        await this.storeGetTicketingServiceTranslation(data);
        this.overlay = false;

        this.ticketingTranslationConfig = this.ticketing.ticketingTranslationServiceConfiguration;
      }
    },
    async confirmConfiguration() {
      this.overlay = true;

      const configurationConfigIsEqual = isEqual(this.ticketingConfigurationCopy.config, this.ticketing.ticketingServiceConfiguration.config);
      const dateLimitIsEqual = isEqual(this.ticketingConfigurationCopy.date_limit, this.ticketing.ticketingServiceConfiguration.date_limit);

      if (!configurationConfigIsEqual || !dateLimitIsEqual) {
        if (!configurationConfigIsEqual) {
          if (this.ticketingConfigurationCopy.ticket_id === '') {
            this.ticketingConfigurationCopy.ticket_id = uuidv4();
            await this.createTicketingServiceAction(this.ticketingConfigurationCopy);
          } else {
            await this.editTicketingServiceAction(this.ticketingConfigurationCopy);

            if (this.ticketingItemsDeleted.document_id.length > 0 || this.ticketingItemsDeleted.meteo_id.length > 0) {
              this.ticketingItemsDeleted.ticket_id = this.ticketingConfigurationCopy.ticket_id;
              await this.deleteTicketingServiceAction(this.ticketingItemsDeleted);
            }
          }
        } else {
          await this.editTicketingServiceAction({
            service_id: this.ticketingConfigurationCopy.service_id,
            ticket_id: this.ticketingConfigurationCopy.ticket_id,
            date_limit: this.ticketingConfigurationCopy.date_limit,
            config: []
          });
        }
      }

      if (this.TicketingServiceTranslationInput.translations.length > 0) {
        await this.createEditTicketingServiceTranslationAction(this.TicketingServiceTranslationInput);
      }

      this.overlay = false;

      this.$router.go(-1);
    },
    setDefaultDate() {
      const defaultDate = moment().subtract(1, 'year').startOf('year').add(10, 'months');
      const formattedDate = defaultDate.format('YYYY-MM-DD');

      this.dateSelected = formattedDate;
    },
    checkDocumentTypeName(document_type) {
      if (document_type.toLowerCase().includes('comunale') || document_type.toLowerCase().includes('provinciale')) {
        this.defaultMeteoTypes = this.DEFAULT_METEO_TYPES.map(meteoType => ({
          ...meteoType,
          is_auto: true,
          is_manual: false
        }));
      } else if (document_type.toLowerCase().includes('puntuale')) {
        this.defaultMeteoTypes = this.PUNTUALE_METEO_TYPES;
      } else {
        this.defaultMeteoTypes = this.DEFAULT_METEO_TYPES;
      }
    },
    openAddItem(itemType, documentType) {
      this.formModel = {
        service_id: this.services.service_id,
        ticket_id: uuidv4(),
        config: {
          document_type: '',
          meteo_types: []
        }
      };

      if (itemType == 'document') {
        this.dialogTitle = 'Document type';
        this.isEditing = false;
        this.dialog = true;
      } else if (itemType == 'meteo') {
        this.dialogTitle = 'Meteo type';
        this.isEditing = false;
        this.dialog = true;
        this.documentTypeSelected = documentType;
      }
    },
    addItem(itemTitle, formValue) {
      if (itemTitle == 'Document type') {
        if (!formValue.config.document_type.trim()) {
          return;
        }

        this.checkDocumentTypeName(formValue.config.document_type);

        this.ticketingConfigurationCopy.config.push({
          document_type: formValue.config.document_type,
          meteo_types: JSON.parse(JSON.stringify(this.defaultMeteoTypes))
        });
      } else if (itemTitle == 'Meteo type') {

        if (!this.meteoTypeName.trim()) {
          this.errorMessage = 'Enter the meteo type name';
          this.showErrorMessage = true;

          return;
        }

        if (!this.is_auto_checkbox && !this.is_manual_checkbox) {
          this.errorMessage = 'The meteo type must be set to auto or manual';
          this.showErrorMessage = true;

          return;
        }

        const documentType = this.ticketingConfigurationCopy.config.find(element => element.document_type === this.documentTypeSelected);

        if (documentType) {
          const newMeteoType = {
            name: this.meteoTypeName,
            is_auto: this.is_auto_checkbox,
            is_manual: this.is_manual_checkbox
          };

          documentType.meteo_types.push(newMeteoType);

          // documentType.meteo_types.push({
          //   name: this.meteoTypeName,
          //   is_auto: this.is_auto_checkbox,
          //   is_manual: this.is_manual_checkbox
          // });
        }
      }

      this.meteoTypeName = '',
      this.is_auto_checkbox = false,
      this.is_manual_checkbox = false,
      this.showErrorMessage = false;
      this.dialog = false;
    },
    openEditItem(itemType, element, item) {
      if (itemType == 'document') {
        this.dialogTitle = 'Document type';
        this.documentTypeSelected = element.document_type;
        this.oldItem = item;
        this.isEditing = true;
        this.dialog = true;
      } else {
        this.dialogTitle = 'Meteo type';
        this.documentTypeSelected = element.document_type;
        this.meteoTypeName = item.name;
        this.is_auto_checkbox = item.is_auto;
        this.is_manual_checkbox = item.is_manual;
        this.oldItem = item;
        this.isEditing = true;
        this.dialog = true;
      }
    },
    editItem(itemTitle, formValue) {
      let index = null;

      if (itemTitle == 'Document type') {
        if (!formValue.config.document_type.trim()) {
          return;
        }

        index = this.ticketingConfigurationCopy.config.findIndex(element => element.document_type === this.documentTypeSelected);

        if (index !== -1) {
          this.ticketingConfigurationCopy.config[index].document_type = formValue.config.document_type;
        }
      } else if (itemTitle == 'Meteo type') {

        if (!this.meteoTypeName.trim()) {
          this.errorMessage = 'Enter the meteo type name';
          this.showErrorMessage = true;

          return;
        }

        if (!this.is_auto_checkbox && !this.is_manual_checkbox) {
          this.errorMessage = 'The meteo type must be set to auto or manual';
          this.showErrorMessage = true;

          return;
        }

        index = this.ticketingConfigurationCopy.config.findIndex(element => element.document_type === this.documentTypeSelected);

        if (index !== -1) {
          const meteoIndex = this.ticketingConfigurationCopy.config[index].meteo_types.findIndex(item => item.name === this.oldItem.name);

          if (meteoIndex !== -1) {
            this.ticketingConfigurationCopy.config[index].meteo_types[meteoIndex].name = this.meteoTypeName;
            this.ticketingConfigurationCopy.config[index].meteo_types[meteoIndex].is_auto = this.is_auto_checkbox;
            this.ticketingConfigurationCopy.config[index].meteo_types[meteoIndex].is_manual = this.is_manual_checkbox;
          }
        }
      }

      // Reset
      this.documentTypeSelected = '';
      this.meteoTypeName = '';
      this.is_auto_checkbox = false;
      this.is_manual_checkbox = false;
      this.dialog = false;
    },
    deleteItem(itemType, element, item) {
      if (itemType == 'document') {
        this.ticketingConfigurationCopy.config.splice(this.ticketingConfigurationCopy.config.indexOf(element), 1);

        if (element.document_id) {
          this.ticketingItemsDeleted.document_id.push(element.document_id);
        }

      } else if (itemType == 'meteo') {

        const meteoIndex = element.meteo_types.findIndex(meteo => meteo.name === item.name);

        if (meteoIndex !== -1) {
          element.meteo_types.splice(meteoIndex, 1);
        }

        if (item.meteo_id) {
          this.ticketingItemsDeleted.meteo_id.push(item.meteo_id);
        }
      }

      this.dialog = false;
    },
    closeDatePicker() {
      if (this.dateSelected) {
        this.dateSelectedText = this.dateSelected;
        this.ticketingConfigurationCopy.date_limit = this.dateSelectedText;
        this.showDatePicker = false;
      }
    },
    openTranslationModal(type, item) {
      if (type === 'document') {
        this.translationDialogTitle = 'Translate Document Type';
        this.itemName = item.document_type;
        this.documentTypeSelected = item;
      } else if (type === 'meteo') {
        this.translationDialogTitle = 'Translate Meteo Type';
        this.itemName = item.name;
        this.meteoTypeSelected = item;
      }

      this.showTranslationModal = true;
      this.selectedLanguage = '';
      this.translationText = '';
    },
    async changeSelectedLanguage(item) {
      const language = item.split('-');

      this.isLanguageSelected = true;
      this.selectedLanguageValue = language[0];
      this.selectedLanguageText = language[1];

      const modalType = this.translationDialogTitle === 'Translate Document Type' ? 'documentType' : 'meteoType';
      const itemId = modalType === 'documentType' ? this.documentTypeSelected.document_id : this.meteoTypeSelected.meteo_id;

      const translation = this.ticketingTranslationConfig.find(translation => {
        return (
          translation.id === itemId &&
          translation.type === modalType &&
          translation.lang === this.selectedLanguageValue
        );
      });

      if (translation) {
        this.translationText = translation.value;
      } else {
        this.translationText = '';
      }
    },
    addTranslation() {
      let translation = {};

      if (this.translationDialogTitle === 'Translate Document Type') {
        translation = {
          id: this.documentTypeSelected.document_id,
          lang: this.selectedLanguageValue,
          type: 'documentType',
          value: this.translationText
        };
      } else {
        translation = {
          id: this.meteoTypeSelected.meteo_id,
          lang: this.selectedLanguageValue,
          type: 'meteoType',
          value: this.translationText
        };
      }

      const existingTranslationIndex = this.TicketingServiceTranslationInput.translations.findIndex(translation => {
        if (this.translationDialogTitle === 'Translate Document Type') {
          return (
            translation.type === 'documentType' &&
            translation.id === this.documentTypeSelected.document_id &&
            translation.lang === this.selectedLanguageValue
          );
        } else if (this.translationDialogTitle === 'Translate Meteo Type') {
          return (
            translation.type === 'meteoType' &&
            translation.id === this.meteoTypeSelected.meteo_id &&
            translation.lang === this.selectedLanguageValue
          );
        }
      });

      if (existingTranslationIndex === -1) {
        this.TicketingServiceTranslationInput.translations.push(translation);
      } else {
        this.TicketingServiceTranslationInput.translations[existingTranslationIndex].value = this.translationText;
      }

      // this.selectedLanguage = '';
      // this.translationText = '';
      this.showTranslationModal = false;
    }
  }
};
</script>
